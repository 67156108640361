import { ReactNode, Ref, forwardRef } from 'react'

import Image from 'next/image'

import clsx from 'clsx'

type Props = {
	className?: string
	image?: { url: string; dimensions: { width: number; height: number } }
	alt: string
	content?: ReactNode
}

export const CardImageFullWidth = forwardRef(function CardImageFullWidth(
	{ className, image, alt, content }: Props,
	ref: Ref<HTMLDivElement>
) {
	return (
		<div ref={ref} className={clsx(className, 'relative')}>
			<div className="max-w-[1156px] mx-auto lg:py-24 grid grid-cols-12">
				{image && (
					<>
						<div
							className="absolute inset-0 bg-cover bg-no-repeat bg-center hidden lg:block"
							style={{ backgroundImage: image && `url(${image.url})` }}
						/>
						<div className="lg:hidden col-span-12">
							<Image
								className="w-full object-cover lg:hidden"
								src={image.url}
								alt={alt}
								width={image.dimensions.width}
								height={image.dimensions.height}
							/>
						</div>
					</>
				)}
				<div className="col-span-12 lg:col-span-5 lg:col-start-7 relative lg:[clip-path:polygon(0_40px,40px_0,110%_0,110%_110%,0_110%)] bg-black text-white px-16 py-12">
					{content}
					<svg
						className="text-bc-green absolute -bottom-4 right-0 hidden lg:block"
						width="81"
						height="81"
						viewBox="0 0 81 81"
					>
						<path d="M0 81L81 0V81H0Z" fill="currentColor" />
					</svg>
				</div>
			</div>
		</div>
	)
})
